import React from 'react'
import WomenFintech from '../components/WomenFintech/WFConfirmation/WomenFintech'
import IndexLayout from '../layouts'
import { LocaleTypes } from '../data/locales';


interface WomanTechProps {
  pathContext: {
    localeCode: LocaleTypes;
  };
  location: Location;
}

const confirmInvite:React.FC<WomanTechProps> = ({location, pathContext}) => {
  return (
    <section className="bg-woman-tech">
      <WomenFintech />
      <div className="wf-image"></div>
    </section>
  )
  // return <IndexLayout render={content} page="confirm-invite" locale={pathContext.localeCode} navbarTheme='default' location={location} /> 
}
export default confirmInvite